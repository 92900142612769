// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---source-index-mdx": () => import("./../../../../source/index.mdx" /* webpackChunkName: "component---source-index-mdx" */),
  "component---source-use-scroll-anchoring-use-scroll-anchoring-mdx": () => import("./../../../../source/useScrollAnchoring/useScrollAnchoring.mdx" /* webpackChunkName: "component---source-use-scroll-anchoring-use-scroll-anchoring-mdx" */),
  "component---source-use-virtualized-list-use-virtualized-list-mdx": () => import("./../../../../source/useVirtualizedList/useVirtualizedList.mdx" /* webpackChunkName: "component---source-use-virtualized-list-use-virtualized-list-mdx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

